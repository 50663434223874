// You can choose any icon from this list: https://react-icons.github.io/react-icons
import {
  FaAngleDown,
  FaCommentDots,
  FaEnvelope,
  FaInstagram
} from 'react-icons/fa';

import {
  SiShopee
} from "react-icons/si";

// This is the color list you can use for now: https://mui.com/material-ui/customization/color/
import {
  lime,
  purple
} from '@mui/material/colors';

import Youtube from './components/Youtube'

// @TODO: Create colorset for light and dark modes.
// @TODO: Properly use button and icon colors or maybe even ditch out
const colors = {
  "mode": "dark",
  "primary": purple,
  "secondary": lime,
  "button": purple,
  "icon": purple,
}

const routes = {
  "sourcecode": "https://gitlab.com/osouza.de/bah-link-tri",
  "instagram": "https://instagram.com/geeky_bunny_",
  "shopee": "https://shopee.com.br/brunatw",
  "email": "mailto:contato@geeky-bunny.com"
}

const icons = [
  {
    "tooltip": "Nosso insta!",
    "icon": <FaInstagram />,
    "route": "instagram"
  },
  {
    "tooltip": "Loja na Shopee",
    "icon": <SiShopee />,
    "route": "shopee"
  },
  {
    "tooltip": "Send me a e-mail!",
    "icon": <FaEnvelope />,
    "route": "email",
  },
]

const items = [
  {
    "title": "Olá!",
    "content": "<p>Somos a Geeky-Bunny.<br />Conheça nossos trabalhos em croche através do nosso insta e compre na nossa loja Shopee.</p><br/><small style='font-style:italic; float: right'>Seja bem vindo!</small><br/>",
    "icon": <FaAngleDown />,
    "type": "accordion",
    "expanded": true
  },
  {
    "title": "Nosso insta!",
    "content": "Our pics's place",
    "icon": <FaInstagram />,
    "route": "instagram",
    "type": "link",
    "hidden": false
  },
  {
    "title": "Shopee",
    "content": "Minha loja na Shopee",
    "icon": <SiShopee />,
    "route": "shopee",
    "type": "link"
  },
  {
    "title": "//extra",
    "content": "<iframe width='100%' src='https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?controls=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
    "icon": <FaAngleDown />,
    "type": "accordion",
    "hidden": true
  },
  {
    "title": "Listen (look) at this",
    "content": <Youtube embedId="dQw4w9WgXcQ?controls=0" />,
    "type": "popup",
    "hidden": true
  }
]

const config = {
  "avatar": "https://down-tx-br.img.susercontent.com/br-11134233-7r98o-lstwkth579rv33_tn.webp",
  "title": "Geeky-Bunny",
  colors,
  icons,
  items,
  routes
}

export default config
